import type firebase from 'firebase/compat/app';
import { mapValues } from 'lodash-es';

import { COLLECTIONS } from 'lib/constants/db';

export const buildCollectionHelpers = <
  Firestore extends firebase.firestore.Firestore | FirebaseFirestore.Firestore,
>(
  firestore: Firestore,
) => {
  type CollectionReference<Doc> = Firestore extends firebase.firestore.Firestore
    ? firebase.firestore.CollectionReference<Doc>
    : FirebaseFirestore.CollectionReference<Doc>;

  type SubCollec<Doc> = <DocId extends string | undefined>(
    docId: DocId,
  ) => DocId extends string ? CollectionReference<Doc> : firebase.firestore.Query<Doc>;

  type Collecs = typeof COLLECTIONS;
  type Return = {
    [key in keyof Collecs]: Collecs[key] extends { subName: string }
      ? SubCollec<Collecs[key]['$doc']>
      : CollectionReference<Collecs[key]['$doc']>;
  };

  return mapValues(COLLECTIONS, (def, collecName) => {
    if (!('subName' in def)) {
      return firestore.collection(collecName);
    }
    const { rootName, subName } = def;

    return (docId?: string) =>
      docId !== undefined
        ? firestore.collection(rootName).doc(docId).collection(subName)
        : firestore.collectionGroup(subName);
  }) as Return;
};
