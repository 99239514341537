import { IS_PROD_DATA } from 'lib/env';
import type { MetaListingDoc } from 'lib/search/syncListings';
import type * as T from 'schemas/types';

const collec = <Doc>() => ({
  $doc: undefined as unknown as Doc,
});

const subCollec =
  <Doc>() =>
  <RootName extends string, SubName extends string>(rootName: RootName, subName: SubName) => ({
    rootName,
    subName,
    $doc: undefined as unknown as Doc,
  });

export const COLLECTIONS = {
  users: collec<T.UserDoc>(),
  listings: collec<T.ListingDoc>(),
  private_listings: collec<T.PrivateListingDoc>(),
  meta_listings: collec<MetaListingDoc>(),
  bookings: collec<T.BookingDoc>(),
  profiles: collec<T.ProfileDoc>(),
  chats: collec<T.ChatDoc>(),
  meta: collec<T.MetaDataDoc>(),
  admins: collec<T.AdminDoc>(),
  user_source_attributions: collec<T.UserSourceAttributionDoc>(),
  product_feedbacks: collec<T.ProductFeedbackDoc>(),
  stripe_customers: collec<T.StripeCustomerDoc>(),
  stripe_accounts: collec<T.StripeAccountDoc>(),
  email_trackers: collec<T.EmailTrackerDoc>(),
  short_links: collec<T.ShortLinkDoc>(),
  install_attributions: collec<T.InstallAttributionDoc>(),
  coupons: collec<T.CouponDoc>(),
  newsletters: collec<T.NewsletterDoc>(),
  listings_feedbacks: collec<T.ListingFeedbackDoc>(),
  saved_listings: collec<T.SavedListingDoc>(),
  monthly_kpis: collec<T.MonthlyKPIsDoc>(),
  support_inquiries: collec<T.SupportInquiryDoc>(),
  photographer_profiles: collec<T.PhotographerProfileDoc>(),
  synced_calendars: collec<T.SyncedCalendarDoc>(),
  holiday_profiles: collec<T.HolidayProfileDoc>(),

  // sub-collections
  profile_reviews: subCollec<T.ProfileReviewDoc>()('profiles', 'reviews'),
  listing_reviews: subCollec<T.ListingReviewDoc>()('listings', 'reviews'),
  vehicles: subCollec<T.VehicleDoc>()('profiles', 'vehicles'),
  notifications: subCollec<T.NotificationDoc>()('users', 'notifications'),
  chat_messages: subCollec<T.ChatMessageDoc>()('chats', 'chat_messages'),
  support_inquiry_messages: subCollec<T.SupportInquiryMessageDoc>()(
    'support_inquiries',
    'support_messages',
  ),
  meta_daily_history: subCollec<T.MetaDailyDoc>()('meta', 'daily_history'),
};

export const BUCKET_IDS = {
  listingImages: IS_PROD_DATA ? 'vanly-listings' : 'vanly-dev-listings',
  avatarImages: IS_PROD_DATA ? 'vanly-avatars' : 'vanly-dev-avatars',
  messageUploads: IS_PROD_DATA ? 'vanly-messageuploads' : 'vanly-dev-messageuploads',
  listingReviewFiles: IS_PROD_DATA ? 'vanly-listingreviews' : 'vanly-dev-listingreviews',
};
export const SERVER_BUCKET_IDS = {
  internal: IS_PROD_DATA ? 'vanly-internal' : 'vanly-internal-dev',
};
