import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import Modal from 'components/Modal';
import Link from 'components/Link';
import useTransition from 'lib/hooks/useTransition';
import { IS_DEV_ENV } from 'lib/env';
import { debugWindowValue, trackError } from 'lib/tracking';
import { createObserver, useObserver } from 'lib/utils/async';

let counter = 1;

const createGlobalPopState = (hideTimeout = null) => {
  const observer = createObserver([], () => {}, true);

  const next = (rawData) =>
    new Promise((resolve) => {
      const key = counter++;

      const data = {
        ...rawData,
        active: true,
        key,
        forceResolve: () => {
          data.canceled = true;
          clearTimeout(data.hideTimer);
          resolve(undefined);
        },
        onComplete: (doneData) => {
          if (data.canceled) return;

          if (hideTimeout != null) {
            const prev = observer.value;
            const el = prev.find((a) => a.key === key);
            if (el) {
              el.active = false;
              observer.next([...prev]);
            }

            data.hideTimer = setTimeout(() => {
              observer.next(observer.value.filter((a) => a.key !== key));
            }, hideTimeout);
          } else {
            observer.next(observer.value.filter((a) => a.key !== key));
          }

          resolve(doneData);
        },
      };

      observer.next([...observer.value, data]);
    });

  const clearAll = () => {
    for (const el of observer.value) el.forceResolve();
    observer.next([]);
  };

  const useThisState = () => useObserver(observer);

  return { useState: useThisState, next, clearAll };
};

const toastsGlobalState = createGlobalPopState(200);
const promptsGlobalState = createGlobalPopState(200);

export const closeAllPrompts = () => promptsGlobalState.clearAll();

const validMsg = (val) => !!val && (typeof val === 'string' || React.isValidElement(val));

const toastRaw = (data) => {
  if (IS_DEV_ENV)
    console.log(`TOAST(${data.variant || 'default'}):`, ...[data.msg, data.link].filter(Boolean));

  if (!validMsg(data.msg)) {
    trackError(`Invalid toast msg`, data);
    return;
  }

  toastsGlobalState.next(data);
};

const info = (msg, options = {}) => toastRaw({ msg, ...options });

const error = (err, options = {}) => {
  const msg = (err && typeof err === 'object' && err.message) || err || 'An unknown error occurred';

  return toastRaw({ msg, variant: 'error', ...options });
};

const success = (msg, options = {}) =>
  toastRaw({ msg, variant: 'success', icon: 'check', ...options });

export const toasts = { info, error, success };

const promptRaw = (data) => {
  if (!validMsg(data.msg) && !data.render) {
    trackError(`Invalid prompt msg`, data);
    return;
  }

  return promptsGlobalState.next(data);
};

const alert = (msg, { cancelMsg = 'Got it' } = {}) => promptRaw({ type: 'alert', msg, cancelMsg });

const confirm = (msg, { cancelMsg = 'Cancel', confirmMsg = 'Confirm' } = {}) =>
  promptRaw({ type: 'confirm', msg, cancelMsg, confirmMsg });

const custom = (render, customProps = {}) => promptRaw({ type: 'custom', render, customProps });

/**
 * @async
 * @param {React.ReactNode} msg
 * @param {Object} options
 * @param {string} [options.cancelMsg="Cancel"]
 * @param {string} [options.confirmMsg="Confirm"]
 * @param {{ label: React.ReactNode, element: React.ReactNode }[]} [options.fields] each object has properties: "label" and React "element"
 * @param {Record<string,unknown>} [options.modalProps]
 * @return {Promise<undefined | Record<string, unknown>>}
 */
const prompt = (
  msg,
  { cancelMsg = 'Cancel', confirmMsg = 'Confirm', fields, modalProps = undefined } = {},
) => promptRaw({ type: 'prompt', msg, cancelMsg, confirmMsg, fields, modalProps });

export const prompts = {
  alert,
  confirm,
  prompt,
  custom,
};

const NOTIFICATION_TIMEOUT = 7000;

const Toast = ({ active, onComplete, msg, index, variant, placement, icon, link }) => {
  const timeoutRef = useRef();

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      onComplete();
    }, NOTIFICATION_TIMEOUT);

    return () => {
      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = (e) => {
    e?.preventDefault();

    clearTimeout(timeoutRef.current);

    onComplete();
  };

  const state = useTransition(active, 200);
  if (!state) return null;

  const Comp = link ? Link : 'div';

  let iconClass, iconColor;
  if (link) {
    iconClass = 'fa-arrow-circle-right';
    iconColor = 'has-text-link';
  } else if (icon === 'check') {
    iconClass = 'fa-check-circle';
    iconColor = 'has-text-success';
  }

  // TODO: alerts can be dynamic heights so we can't hardcode `60` here
  const alertHeight = 60;
  const alertSpacing = 20;
  const ySign = placement === 'top' ? -1 : 1;
  let dist;
  if (state === 'in' || state === 'active') {
    dist = (alertHeight + alertSpacing) * index * -ySign;
  } else {
    dist = (alertHeight + 2 * alertSpacing) * ySign;
  }

  return (
    <>
      {createPortal(
        <Comp
          {...(link ? { href: link } : {})}
          style={{
            transform: `translateY(${dist}px)`,
          }}
          className={clsx(
            'toast notification',
            placement === 'top' && 'toast--top',
            variant === 'success' && 'toast--success',
            variant === 'error' && 'toast--error',
            (state === 'in' || state === 'active') && 'fade-in',
          )}
        >
          {iconClass ? (
            <i
              className={clsx('fas fa-lg', iconClass, iconColor)}
              style={{ margin: '-4px 1.25rem -4px 0' }}
            />
          ) : null}
          {msg}

          <button className="delete" aria-label="Close" onClick={onClose} />
        </Comp>,
        document.body,
      )}

      <style jsx>{`
        @import 'styles/variables';

        $transition: 200ms ease-out;

        .toast {
          position: fixed;
          z-index: 50;
          left: 1rem;
          right: 1rem;
          bottom: 1rem;
          margin: 0 auto;

          pointer-events: all;

          // bulma's box-shadow
          box-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1);
          min-width: 320px;
          max-width: 420px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          transition: opacity $transition, transform $transition;
          opacity: 0;

          color: $text;
          background: white;
          border: 2px solid $primary;

          &--success {
            border: 2px solid $success;
          }

          &--error {
            background: lighten($danger, 35%);
            border: 2px solid $danger;
          }

          &--top {
            top: 1rem;
            //right: unset;
            bottom: unset;
          }

          &.fade-in {
            opacity: 1;
          }
        }
      `}</style>
    </>
  );
};

const Prompt = ({
  active,
  onComplete,
  type,
  msg,
  cancelMsg,
  confirmMsg,
  fields,
  render,
  modalProps = {},
  customProps,
}) => {
  if (type === 'custom')
    return render({
      active,
      onComplete,
      ...customProps,
    });

  const onSubmit = (values) => {
    if (type === 'prompt') {
      onComplete(values);
    } else {
      onComplete(type === 'confirm' ? true : undefined);
    }
  };

  const onClose = () => {
    onComplete(type === 'confirm' ? false : undefined);
  };

  return (
    <Modal
      fullScreen={false}
      {...modalProps}
      active={active}
      header={null}
      labels={{ cancel: cancelMsg, confirm: confirmMsg }}
      isForm
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <div className="is-size-6">{msg}</div>
      {fields?.map(({ label, element }, i) => {
        // const id = `prompt-input-${i}`;

        return (
          <div key={i} className="field">
            {label ? <label className="label">{label}</label> : null}
            <div className="control">{element}</div>
          </div>
        );
      })}
    </Modal>
  );
};

export const Alerts = () => {
  const toastItems = toastsGlobalState.useState();
  const promptItems = promptsGlobalState.useState();

  return (
    <>
      {toastItems.map(({ key, ...data }, i) => (
        <Toast key={key} index={i} {...data} />
      ))}
      {promptItems.map(({ key, ...data }, i) => (
        <Prompt key={key} index={i} {...data} />
      ))}
    </>
  );
};

debugWindowValue('toasts', toasts);
debugWindowValue('prompts', prompts);
